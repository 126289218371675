.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.transition-group {
  position: relative;
}

.pageSlider-enter {
  opacity: 0;
  transform: scale(1.1);
}

.pageSlider-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.pageSlider-exit {
  opacity: 1;
  transform: scale(1);
}

.pageSlider-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


.navi-font-ani-enter {
  /* font-weight: bold; */
  color: red;
}

.navi-font-ani-enter-active {
  /* font-weight: normal; */
  color: white;
  transition: all 1000ms;
}


.ncscode-not-selected {
  border-color: red;
  color: red;
}

.animation-ncscode-not-selected {
  animation-duration: 7s;
  animation-name: ncs-code-alert;
  animation-iteration-count: infinite;
}

@keyframes ncs-code-alert {
  3% {
    border-color: #ced4da;
    color: black;
  }

  6% {
    border-color: red;
    color: red;
  }

  9% {
    border-color: #ced4da;
    color: black;
  }

  12% {
    border-color: red;
    color: red;
  }



}